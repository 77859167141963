@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind base;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;

        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;

        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;

        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;

        --border: 216 34% 17%;
        --input: 216 34% 17%;

        --card: 224 71% 4%;
        --card-foreground: 213 31% 91%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;

        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;

        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;

        --ring: 216 34% 17%;

        --radius: 0.5rem;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
        font-feature-settings: "rlig" 1, "calt" 1;
    }
}

body {
    margin: 0;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.show-child {
    display: none;
}
.show-children:hover .show-child {
    display: flex;
}
.hover-display .hover-display-children {
    display: none;
}
.hover-display:hover .hover-display-children {
    display: flex;
}
.sidebar-display .sidebar-display-children {
    display: none;
}
.sidebar-display:hover .sidebar-display-children {
    display: flex;
}

@keyframes fadeInSidebarChildren {
    0% {
        opacity: 0;
        height: 0px;
        max-height: 0px;
    }
    90% {
        height: 0px;
        max-height: 0px;
    }
    100% {
        opacity: 1;
        height: 40px;
        max-height: none;
    }
}
@keyframes fadeInSidebar {
    from {
        opacity: 0;
        height: 8px;
        max-height: 8px;
    }
    to {
        opacity: 1;
        height: 40px;
        max-height: none;
    }
}
.sidebar-display-items {
    height: 8px;
}
.sidebar-display-items .sidebar-display-items-children {
    display: none;
}
.sidebar-display-items:hover,
.sidebar-display-items:focus,
.sidebar-display-items:active,
.sidebar-display-items:focus-visible {
    animation-name: fadeInSidebar;
    animation-duration: 1.4s;
    animation-fill-mode: forwards;
}
.sidebar-display-items:hover .sidebar-display-items-children {
    display: flex;
    animation-name: fadeInSidebarChildren;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}

/* Scroll bar general background */
::-webkit-scrollbar-corner {
    @apply bg-zinc-900;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    @apply bg-zinc-900;
}

/* Track */
::-webkit-scrollbar-track {
    @apply bg-zinc-900;
}

/* Handle */
::-webkit-scrollbar-thumb {
    @apply bg-zinc-700 rounded;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @apply bg-blue-600  rounded;
}

::-webkit-scrollbar-thumb:active {
    @apply bg-blue-600  rounded;
}

/* width */
::-moz-scrollbar {
    width: 6px;
    height: 6px;
    @apply bg-zinc-700;
}

/* Track */
::-moz-scrollbar-track {
    @apply bg-zinc-900;
}

/* Handle */
::-moz-scrollbar-thumb {
    @apply bg-zinc-700;
}

/* Handle on hover */
::-moz-scrollbar-thumb:hover {
    @apply bg-blue-600;
}

::-moz-scrollbar-thumb:active {
    @apply bg-blue-600;
}
